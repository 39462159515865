<template>
  <div class="clients__body">
    <div class="clients__flex d-flex flex-column">
      <div
        class="clients-item clients-item-min item__start d-flex justify-start align-i-center mb-10"
      >
        <div class="item__img relative">
          <img
            src="../assets/images/clients-img-1.png"
            alt=""
            class="absolute"
          />
        </div>
        <div class="item__content">
          <ul class="item__list">
            <li class="item__text">1 ticket to the Super Bowl</li>
            <li class="item__text">1 Round trip airfare ticket</li>
            <li class="item__text">2 nights lodging at a Hilton brand hotel</li>
          </ul>
        </div>
      </div>
      <div
        class="clients-item item__between item__coustom d-flex align-i-center mb-5 reverse"
      >
        <div class="item__img relative">
          <img
            src="../assets/images/clients-cap-1.png"
            alt=""
            class="absolute"
          />
        </div>
        <div class="item__content item__content-b">
          <p class="item__text item__text-large">
            Joe Burrow Cincinnati Bengals Autographed Riddell Super Bowl LVI
            Speed Replica
          </p>
        </div>
      </div>
      <div
        class="clients-item item__between item__coustom d-flex align-i-center mb-5"
      >
        <div class="item__img relative">
          <img
            src="../assets/images/clients-cap-2.png"
            alt=""
            class="absolute"
          />
        </div>
        <div class="item__content item__content-m">
          <p class="item__text item__text-large">
            Cincinnati Bengals Riddell Full Size Speed Replica Helmet-On Field
            Alternate 2022
          </p>
        </div>
      </div>
      <div
        class="clients-item item__between item__coustom d-flex align-i-center mb-5 reverse"
      >
        <div class="item__img relative">
          <img
            src="../assets/images/clients-cap-3.png"
            alt=""
            class="absolute"
          />
        </div>
        <div class="item__content item__content-b">
          <p class="item__text item__text-large">
            Helmet Fanatics Authentic Ja'Marr Chase Cincinnati Bengals 2021 NFL
            Offensive Rookie of the Year Autographed Speed Authentic Helmet with
            "21 NFL OROY" Inscription.
          </p>
        </div>
      </div>
      <div
        class="clients-item clients-item-large item__start d-flex align-i-center mb-25"
      >
        <div class="item__img relative">
          <img
            src="../assets/images/clients-img-2.png"
            alt=""
            class="absolute"
          />
        </div>
        <div class="item__content item__content-eb">
          <p class="item__text item__text-large">
            <span class="item__link">2 Night Stay on the new Star Wars:</span>
            Galactic Starcruiser for a family of up to 5.
            <span class="item__link line">The itinerary includes</span> three
            different story moments that allow the guest to choose their path
            over the two days. In addition to various food and entertainment
            opportunities throughout both days, the second day includes a
            morning trip to Batuu at Disney’s Hollywood Studios, which include a
            ride on <span class="item__link italic">Star Wars:</span> Rise of
            Resistance, a ride on Millennium Falcon: Smugglers Run, and lunch at
            the Docking Bay 7 Food and Cargo.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClientsBody",
};
</script>

<style lang="scss" scoped>
.clients-item {
  &.item__coustom {
    .item__img {
      margin: 0px 210px 0px 0px;
    }
  }
  &.reverse {
    flex-direction: row-reverse;
    justify-content: flex-end;
    .item__img {
      margin: 0px 0px 0px 210px;
    }
  }
  &.item__start {
    .item__img {
      width: 350px;
      background: #ebebeb;
      height: 200px;
      border-radius: 7px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &.item__between {
    .item__img {
      width: 250px;
      height: 250px;
      background: #ebebeb;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }
  &.clients-item-min {
    .item__content {
      padding: 0px 0px 0px 60px;
    }
  }
}
.item {
  &__content {
    padding: 0px 0px 0px 0;
    &.item__content-b {
      .item__text {
        width: 570px;
      }
    }
    &.item__content-m {
      .item__text {
        width: 540px;
      }
    }
    &.item__content-eb {
      padding: 0 0 0 15px;
      .item__text {
        width: 620px;
      }
    }
  }
  &__text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    color: $black;
    font-size: 16px;
    line-height: 1.38;
  }
  &__list {
    list-style: none;

    li:first-child {
      margin: 0 0 12px 0;
    }
    li {
      margin: 12px 0;
      padding: 0 0 0 22px;
      background: url("../assets/images/clients-list-ic.svg") 0 6.5px / 10px
        no-repeat;
    }
  }
  &__link {
    color: #193d7c;
    text-decoration: none;
    &.line {
      text-decoration: underline;
    }
    &.italic {
      font-style: italic;
    }
  }
}

@media only screen and (max-width: 1206px) {
  .item {
    &__content {
      &.item__content-eb {
        padding: 0px 0px 0px 30px;
      }
    }
  }
}
@media only screen and (max-width: 1090px) {
  .clients-item {
    &.item__coustom {
      .item__img {
        margin: 0px 100px 0px 0px;
      }
    }
    &.reverse {
      .item__img {
        margin: 0px 0px 0px 100px;
      }
    }
  }
}
@media only screen and (max-width: 1056px) {
  .clients-item {
    flex-wrap: wrap;
  }
  .item {
    &__content {
      padding: 0px 0px 0px 30px;
      &.item__content-b {
        .item__text {
          width: 500px;
        }
      }
      &.item__content-m {
        .item__text {
          width: 460px;
        }
      }
      &.item__content-eb {
        .item__text {
          width: 520px;
        }
      }
    }
  }
}
@media only screen and (max-width: 960px) {
  .clients-item-large {
    flex-wrap: nowrap;
  }
  .item {
    &__content {
      padding: 0;
      &.item__content-eb {
        padding: 15px 0px 0px 0px;
        .item__text {
          width: 470px;
        }
      }
    }
  }
}
@media only screen and (max-width: 880px) {
    .clients-item {
    &.item__coustom {
      .item__img {
        margin: 0px 45px 0px 0px;
      }
    }
    &.reverse {
      .item__img {
        margin: 0px 0px 0px 45px;
      }
    }
  }
  .clients-item {
    &.clients-item-min {
      .item__content {
        padding: 0 0px 0px 15px;
      }
    }
    &.item__between {
      .item__img {
        width: 180px;
        height: 180px;
      }
    }
  }
  .item {
    &__content {
      padding: 0px 0px 0px 0;
    }
  }
}

@media only screen and (max-width: 770px) {
  .item {
    &__content {
      &.item__content-b {
        .item__text {
          width: 400px;
        }
      }
      &.item__content-m {
        .item__text {
          width: 400px;
        }
      }
    }
  }
}
@media only screen and (max-width: 660px) {
  .clients-item {
    flex-direction: column;
    > div {
      margin: 0 auto;
    }
    &.item__between {
      .item__img {
        margin: 0 auto 15px auto;
      }
    }
    &.item__coustom {
      flex-direction: column;
    }
    .item {
      &__content {
        &.item__content-eb {
          .item__text {
            width: 100%;
          }
        }
      }
    }
  .clients-item {
    &.item__coustom {
      .item__img {
        margin: 0px 0 0px 0px;
      }
    }
    &.reverse {
      .item__img {
        margin: 0px 0px 0px 0;
      }
    }
  }
  }
}
@media only screen and (max-width: 520px) {

  .item {
    &__content {
      .item__text {
        &.item__text-large {
          width: 100%;
        }
      }
    }
  }
}
@media only screen and (max-width: 520px) {
  .clients-item {
    &.item__start {
      .item__img {
        width: 95%;
      }
    }
  }
}
</style>
