<template>
  <div class="clients">
    <div class="clients-content">
      <div class="content__section">
        <div class="content__top">
          <div class="content__text">
            A Guest Law Office, we value our clients and their loyalty to our
            firm. Our Loyalty Program is an opportunity where clients can win
            fabulous rewards such as unique memorabilia and trips for referrals
            and continued business. We recommend that you keep checking this
            page periodically as we will be adding more ways for you to earn
            more opportunities to win! We always love hearing from you, please
            feel free to reach out to us by calling 859-227-09720 or by email
            you have any questions. It's how we say...thank you!
          </div>
        </div>
        <clients-body></clients-body>
      </div>
    </div>
  </div>
</template>

<script>
import ClientsBody from "@/components/ClientsBody.vue";
export default {
  data() {
    return {};
  },
  components: {
    ClientsBody,
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  },
};
</script>

<style lang="scss" scoped>
.clients-content {
  background: #ebebeb;
}
.content {
  &__section {
    max-width: 1187px;
    margin: 0 auto;
  }

  &__top {
    padding: 100px 0px 40px 0px;
  }
  &__text {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    color: $black;
    font-size: 16px;
    line-height: 1.38;
  }
}
@media only screen and (max-width: 880px) {
  .main__section {
    text-align: center;
  }
}

</style>
